'use client';

import { createClient } from '@/lib/supabase/client';
import { Button } from '@mantine/core';
import { IconBrandGithub } from '@tabler/icons-react';

export default function GitHubButton() {
  const supabase = createClient();

  const handleSignIn = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'github',
      options: {
        redirectTo: `${window.location.origin}/auth/callback`,
      },
    });
  };

  return (
    <Button
      onClick={handleSignIn}
      leftSection={<IconBrandGithub size="1rem" stroke={1.5} />}
      variant="default"
    >
      Continue with GitHub
    </Button>
  );
}
