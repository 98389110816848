'use client';

import { Button, ButtonProps } from '@mantine/core';
import { useFormStatus } from 'react-dom';

interface MyButtonProps extends ButtonProps {
  pendingText?: string;
  formAction?: string | ((formData: FormData) => Promise<void>) | undefined;
}

export function SubmitButton({ children, pendingText, ...props }: MyButtonProps) {
  const { pending, action } = useFormStatus();

  const isPending = pending && action === props.formAction;

  return (
    <Button fullWidth my="xs" type="submit" aria-disabled={pending} loading={pending} {...props}>
      {isPending ? pendingText : children}
    </Button>
  );
}
